










import Topbar from "@/components/StoreFront/Panel/TheTopbar.vue";
import Sidebar from "@/components/StoreFront/Panel/TheSidebar.vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import StoreSubscriber from "@/store/StoreSubscriber";
import { Source } from "@/models";
import { namespace } from "vuex-class";
import { sourceStore } from "@/store/typed";

@Component({ components: { Topbar, Sidebar } })
export default class StoreFrontPanel extends Vue {

  mounted() {
    this.subscribe();
  }
  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    if (sourceStore.currentSource !== source) {
      this.$router.push({ name: "discounts" });
    }
  }
}
