











































































import { Component, Vue } from "vue-property-decorator";
import HeaderSelect from "@/components/Source/HeaderSelect.vue";
import { StoreFront, Source } from "@/models";
import { NoCache } from "@/utils/Decorators";
import Credits from "@/components/Credits.vue";
import { currentUserStore, sourceStore, storeFrontStore } from "@/store/typed";

export interface SidebarItem {
  label: string;
  name: string;
  locked?: boolean;
  icon?: string;
  disabled?: boolean;
  params?: any;
}

@Component({
  components: {
    HeaderSelect,
    Credits,
  },
})
export default class TheSidebar extends Vue {
  open = false;
  openedGroup: string | null = null;

  get storeFront(): StoreFront {
    return storeFrontStore.storeFront;
  }

  get user() {
    return currentUserStore.user;
  }

  get source(): Source {
    return sourceStore.currentSource;
  }

  toggleGroup(groupName: string) {
    if (this.openedGroup !== groupName) {
      this.openedGroup = groupName;
    } else {
      this.openedGroup = null;
    }
  }

  visible(roles: string[]) {
    return roles?.includes(this.user.role) || !roles?.length;
  }

  backToStore() {
    this.$router.push({ name: "source_webhooks" });
  }

  @NoCache
  get sidebarItems(): SidebarItem[] {
    return [
      { label: "Sconti", name: "store_front_discounts_list", icon: "store" },
      {
        label: "Contenuti",
        name: "store_front_contents_dashboard",
        params: { id: this.storeFront.id },
        icon: "image",
      },
      {
        label: "Ateprima",
        name: "store_front_preview_dashboard",
        params: { id: this.storeFront.id },
        icon: "mobile-android",
      },
    ];
  }

  get sidebarOpen() {
    return this.isTablet && !this.open;
  }

  get style() {
    return this.isTablet && this.open ? { "z-index": 2000 } : {};
  }

  get isSuperAdmin(): boolean {
    if (!this.user) {
      return false;
    }

    return this.user.isSuperAdmin;
  }

  get isTablet() {
    return this.$mq === "sm" || this.$mq === "md" || this.$mq === "lg";
  }
}
